import React from "react";
import { useNavigate , useLocation } from "react-router-dom";
import "./style.scss";

const getTimeString = timeZone => new Intl.DateTimeFormat("en-US", {
  timeZone,
  timeZoneName : "short",
  hour         : "numeric",
  minute       : "numeric",
})
  .format(new Date())
  .split(" ")
  .slice(0, 2)
  .join("")
  .toLowerCase()
  .replace(":", "-");

const Footer = ({ setAboutOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="footer">
      <div className="client-login">
        { location.pathname === "/" ? (
          <div onClick={() => navigate("/login")}> CLIENT LOGIN </div>
        ) : (
          <div onClick={() => navigate("/")}> HOME </div>
        )}
        <div onClick={() => setAboutOpen(true)}> ABOUT </div>
      </div>
      <div className="gap" />
      <div className="office-div">
        <p> LA Office </p>
        <p className="time"> { getTimeString("America/Los_Angeles") } </p>
      </div>
      <div className="office-div">
        <p> UK Office </p>
        <p className="time"> { getTimeString("Europe/London") } </p>
      </div>
    </div>
  );
};

export default Footer;
