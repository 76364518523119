/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import nextImg from "src/assets/next.svg";
import "./style.scss";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();

  const onClickNext = () => {
    if (email && password) {
      setNotFound(true);
    }
  };

  return (
    <div className="login-page">
      <div className="content">
        { notFound && (
          <p className="error-message">
            Digital Giant does not have record of this account.
            Please contact your Digital Giant Representative for access.
          </p>
        )}
        <input
          type="text"
          placeholder="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <br />
        <input
          type="password"
          placeholder="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <div className="control-div">
          <span onClick={() => navigate("/reset")}> reset password </span>
          <img src={nextImg} alt="next" onClick={onClickNext} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
