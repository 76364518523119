import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import axios from "axios";
import SignIn from "src/App/pages/SignIn";
import Home from "src/App/pages/Home";
import Reset from "src/App/pages/Reset";
import Footer from "src/App/components/Footer";
import About from "src/App/components/About";
import logo from "src/assets/logo.svg";
import { getWeatherTexts } from "./utils";
import "./app.scss";

const ipApiKey = "at_5fY4UpLc6Rj4hSW2bYmqNFvH3U3Ax";
const weatherApiKey = "f623eaf187a52afeb9766839a9e2b46c";

const App = () => {
  const [data, setData] = useState(null);
  const [texts, setTexts] = useState(null);
  const [aboutOpen, setAboutOpen] = useState(false);

  useEffect(() => {
    const start = async () => {
      const response = await axios({
        url: "https://geo.ipify.org/api/v1",
        params: { apiKey: ipApiKey }
      });
      setData(response.data);

      const weatherResponse = await axios({
        url: "https://api.openweathermap.org/data/2.5/onecall",
        params: {
          lat: response.data.location.lat,
          lon: response.data.location.lng,
          exclude: "hourly,minutely",
          appid: weatherApiKey
        }
      });

      setTexts([
        `I see you're in ${response.data?.location?.city}`,
        ...getWeatherTexts(weatherResponse.data),
      ]);
    };
    start();
  }, []);

  return (
    <div className="app-container">
      <Router>
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/" element={<Home data={data} texts={texts} />} />
        </Routes>

        <div className="page-logo">
          <img src={logo} alt="logo" />
        </div>
        <Footer setAboutOpen={setAboutOpen} />
        <About open={aboutOpen} onClose={() => setAboutOpen(false)} />
      </Router>
    </div>
  );
};

export default App;
