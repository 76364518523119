import React, { useEffect, useState } from "react";
import {
  getRandomText, getSubHeaderText, wordInterval
} from "src/App/utils";
import "./style.scss";

const animationDuration = 1000;
let previousTime = -1;

const SubHeader = ({ texts }) => {
  const [text, setText] = useState(null);

  const animateText = (newText) => {
    const timerInterval = 50;

    let time = 0;
    const intervalId = setInterval(() => {
      time += timerInterval;

      // End interval
      if (time > animationDuration) {
        clearInterval(intervalId);
        setText(newText);
        return;
      }

      // Set random text
      setText(getRandomText());
    }, timerInterval);
  };

  const doScramble = () => {
    if (previousTime < 0) {
      return;
    }
    if (Date.now() - previousTime < animationDuration + 1000) {
      return;
    }
    previousTime = Date.now();
    animateText(getSubHeaderText());
  };

  useEffect(() => {
    let index = 0;
    animateText(texts[index]);
    const intervalId = setInterval(() => {
      index++;
      if (index >= texts.length) {
        clearInterval(intervalId);
        previousTime = 0;
        doScramble();
        return;
      }

      animateText(texts[index]);
    }, wordInterval);

    const intervalId_20 = setInterval(() => {
      if (previousTime < 0) {
        return;
      }
      if (Date.now() - previousTime < 20 * 1000) {
        return;
      }

      doScramble();
    }, 1000);

    document.addEventListener("mousemove", doScramble, true);
    document.addEventListener("touchstart", doScramble, true);

    return () => {
      document.removeEventListener("mousemove", doScramble, true);
      document.removeEventListener("touchstart", doScramble, true);
      clearInterval(intervalId);
      clearInterval(intervalId_20);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="subheader">
      { text }
    </div>
  );
};

export default SubHeader;
